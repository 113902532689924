<template>
  <div>
    <!-- <h4 class="display-1">
      {{
        footer_version
          ? $t("global.contact_component.visit_card.title_questions")
          : "Strataggem"
      }}
    </h4> -->
    <base-subheading class="display-1">
      {{ $t("global.pages_titles.contact") }}
    </base-subheading>

    <div class="mt-7">
      <template v-if="footer_version">
        <p>
          Strataggem
          <br />
          8 Place de l'Hôpital,
          <br />
          67000 Strasbourg
        </p>
        <p class="dark--text">
          contact <v-icon small>mdi-at</v-icon> strataggem.com
        </p>
      </template>

      <template v-else>
        <div class="d-flex flex-row mb-5">
          <v-icon class="pr-3">mdi-map-marker</v-icon>
          <div class="d-inline-flex">
            8 Place de l'Hôpital,
            <br />
            67000 Strasbourg
          </div>
        </div>

        <div class="d-flex flex-row">
          <v-icon class="pr-3">mdi-email</v-icon>
          <div class="d-inline-flex">
            contact <v-icon small>mdi-at</v-icon> strataggem.com
          </div>
        </div>
      </template>
    </div>
  </div>
</template>



<script>
export default {
  props: {
    footer_version: { type: Boolean, default: false },
  },
};
</script>
